import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/Seo"
import Featured from "../components/Featured"

import { LinearH1 } from "./../styles/elements.js"

import styled from "styled-components"

const Paragraph = styled.div`
  padding-left: 10%;
`
const SLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-weight: bolder;
  font-size: 1.5em;
  :hover {
    border-bottom: 1px solid goldenrod;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Featured>
      <Paragraph>
        <Seo title="404: Not found" />
        <LinearH1>Page Not Found</LinearH1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          You can go back <SLink to="/">Home</SLink> to continue your journey
          towards greatness.
        </p>
      </Paragraph>
    </Featured>
  </Layout>
)

export default NotFoundPage
